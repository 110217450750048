<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="설비목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :editable="editable"
          noDataLabel="정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다."
          selection="multiple"
          cardClass="topcolor-orange"
          rowKey="minEquipmentMaintenanceId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- <c-btn v-if="editable && updateMode" :showLoading="false" label="계획완료" icon="save" color="blue"  @btnClicked="planComplete" /> -->
              <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
              <c-btn
                v-if="editable"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" />
              <c-btn v-if="editable && grid.data.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-maintenance-plan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        disabled: false,
        minEquipmentMaintenanceId: '',
        checkDate: '',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentMaintenanceId: '',
        plantCd: '',
        equipmentCd: '',
        maintenanceStatusCd: '',
        equipmentTypeCd: '',
        equipmentCheckTypeCd: '',
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        mocId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceDate: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: '',
        maintenanceCompleteFlag: '',
        noPlanResultEnrollFlag: '',
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceTime: '',
        stopDrivingTime: '',
        reviewOpinionOfMananger: '',
      },
      grid: {
        columns: [
          // {
          //   name: 'plantNm',
          //   field: 'plantNm',
          //   label: '사업장',
          //   align: 'center',
          //   sortable: true
          // },
          {
            name: 'memDeptEntrantUserName',
            field: 'memDeptEntrantUserName',
            label: '정비부서 입회자',
            required: true,
            align: 'center',
            style: 'width:120px',
            setHeader: true,
            type: 'user',
            userId: 'memDeptEntrantUserId',
            sortable: false,
          },
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            required: true,
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            required: true,
            label: '설비코드',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            required: true,
            style: 'width:120px',
            align: 'left',
            sortable: false
          },
          {
            name: 'equipmentCheckTypeCd',
            field: 'equipmentCheckTypeCd',
            label: '정비유형',
            required: true,
            align: 'center',
            style: 'width:130px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MMKC00005', codeName: '예방정비' },
              { code: 'MMKC00010', codeName: '예측정비' },
              { code: 'MMKC00015', codeName: '고장정비' },
              { code: 'MMKC00020', codeName: '운전정비' },
            ],
            sortable: false,
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            label: '정비예정자',
            required: true,
            align: 'center',
            style: 'width:120px',
            setHeader: true,
            type: 'user',
            userId: 'maintenanceDueUserId',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            label: '정비예정일',
            required: true,
            align: 'center',
            style: 'width:150px',
            type: 'date',
            sortable: false,
          },
          {
            name: 'pmCycleName',
            field: 'pmCycleName',
            label: '예방정비주기',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'recentMaintainDate',
            field: 'recentMaintainDate',
            label: '최근정비일',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   align: 'left',
          //   style: 'width:200px',
          //   type: 'textarea',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '700px'
      },
      title: '',

      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',
      disabled: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isSave: false,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // list setting
      this.insertUrl = transactionConfig.sop.min.maintenance.plan.insert.url;
      this.deleteUrl = transactionConfig.sop.min.maintenance.plan.delete.url;
      this.completeUrl = transactionConfig.sop.min.maintenance.plan.complete.url;

      if (this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.grid.data.splice(0, 0, {
            minEquipmentMaintenanceId: uuidv4(),
            plantCd: _item.plantCd,
            plantNm: _item.plantNm,
            memDeptEntrantUserId: 'D10003',
            memDeptEntrantUserName: '안전환경팀',  
            mocId: '',
            equipmentCheckTypeCd: null,
            maintenanceStatusCd: 'MCSC000005',
            maintenanceDueUserName: '', 
            maintenanceDueUserId: '',
            maintenanceDueDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeNm: _item.equipmentTypeNm,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            pmCycleName: _item.pmCycleName,
            pmCycleCd: _item.pmCycleCd,
            recentMaintainDate: _item.recentMaintainDate,
            noPlanResultEnrollFlag: 'N',
            relatedLaws: _item.relatedLaws,
            relatedLawsName: _item.relatedLawsName,
            nextMonth: _item.nextMonth,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            month: 'temp',
            editFlag: 'C',
          })  
        })
      }
      this.title = this.popupParam.title;

      // list setting
      this.title = this.popupParam.title;
      // this.getList();
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.equipmentCd, this.popupParam.checkDate);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.updateMode = true;
        },);
      }
    },
    add() {
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        title: '정비'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.grid.data.splice(0, 0, {
            minEquipmentMaintenanceId: uuidv4(),
            plantCd: _item.plantCd,
            plantNm: _item.plantNm,
            memDeptEntrantUserId: '',
            memDeptEntrantUserName: '',  
            mocId: '',
            equipmentCheckTypeCd: null,
            maintenanceStatusCd: 'MCSC000005',
            maintenanceDueUserName: '', 
            maintenanceDueUserId: '',
            maintenanceDueDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeNm: _item.equipmentTypeName,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            pmCycleName: _item.pmCycleName,
            pmCycleCd: _item.pmCycleCd,
            recentMaintainDate: _item.recentMaintainDate,
            noPlanResultEnrollFlag: 'N',
            relatedLaws: _item.relatedLaws,
            relatedLawsName: _item.relatedLawsName,
            nextMonth: _item.nextMonth,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            month: 'temp',
            editFlag: 'C',
          })
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
            this.grid.data = this.$_.reject(this.grid.data, { minEquipmentMaintenanceId: item.minEquipmentMaintenanceId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveInspection() {
      let isConti = true;
      if (this.grid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 추가하세요.', // 설비를 추가하세요.
          type: 'warning', // success / info / warning / error
        });
        isConti = false;
        return;
      }
      let checkItem = ['memDeptEntrantUserName', 'equipmentCheckTypeCd', 'maintenanceDueUserName', 'maintenanceDueDate' ]
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 정비예정자, 정비예정일]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      this.emit('SAVE');
    },
    planComplete() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let flag = true;
        if (selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '설비를 추가하세요.', // 설비를 추가하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = false;
          return;
        }
        this.$_.forEach(selectData, item => {
          if (!item.equipmentCheckTypeCd) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: 
              // item.equipmentNm + 
              '정비유형을 추가하세요.', // 정비유형을 추가하세요.
              type: 'warning', // success / info / warning / error
            });
            flag = false;
            return;
          }
          if (!item.maintenanceDueUserName) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: 
              // item.equipmentNm + 
              '점검예정자를 선택하세요.', // 점검예정자를 선택하세요
              type: 'warning', // success / info / warning / error
            });
            flag = false;
            return;
          }
          if (!item.maintenanceDueDate) {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: 
              // item.equipmentNm + 
              '점검예정일을 선택하세요.', // 점검예정일을 선택하세요.
              type: 'warning', // success / info / warning / error
            });
            flag = false;
            return;
          }
        })
        if (flag) {
          window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장되지 않은 설비점검은 저장과 함께 계획완료 됩니다. 계획완료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.emit('COMPLETE')
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
        }
      }
    },
    select(type) {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: 
            '하나 이상 선택하세요.', // 하나 이상 선택하세요
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      if (type === 'USER') {
        this.popupOptions.title = '정비 예정자 검색'; // 정비 예정자 검색
        this.popupOptions.param = {
          type: 'multiple'
        };
        this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
        this.popupOptions.width = '50%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeUserPopup;
      }
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (!data) return;

      this.$_.forEach(this.grid.data, _item => {
        _item.chkUserNm = data[0].userNm;
        _item.chkUserId = data[0].userId;
      })
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === '계획완료' || type === '저장') {
         window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: type +  ' 하였습니다.', 
          type: 'success', // success / info / warning / error
        });
      }
      this.getList();
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
  }
};
</script>
